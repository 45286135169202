import { faHouse, faUser } from '@fortawesome/pro-regular-svg-icons';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import type { Crumble, Environment, LocalizedSlugs, Navigation, Nullable } from '@boss/types/b2b-b2c';
import { BreadCrumbs, Container, Footer, Portal, RefreshBanner, ScrollToTop } from '@boss/ui';

import SubscribeForm from '../components/Form/SubscribeForm';
import Header from '../components/Header';
import PWAWrapper from '../components/PWAWrapper';
import { useProfile } from '../hooks';
import { displayPWAPopup, isB2b } from '../utils';

type Props = {
  children: ReactNode;
  environment?: Environment;
  preview?: boolean;
  navigation: Nullable<Navigation>;
  localizedSlugs: LocalizedSlugs;
  className?: string;
  crumbles?: Crumble[];
  breadcrumbWrapperClassName?: string;
  breadcrumbClassName?: string;
  breadcrumbHasSmallSpacing?: boolean;
  containerWrapperClassName?: string;
  childrenClassName?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  type?: 'default' | 'simple';
};

const WrapperStyles = cva('', {
  variants: {
    variant: {
      primary: '',
      secondary: '',
      tertiary: 'md:bg-white-dark',
    },
  },
});

const Layout = ({
  children,
  environment = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT as Environment,
  preview,
  navigation,
  localizedSlugs,
  className,
  crumbles,
  breadcrumbClassName,
  breadcrumbWrapperClassName,
  childrenClassName,
  containerWrapperClassName,
  variant = 'primary',
  type = 'default',
}: Props) => {
  const isDev = environment !== 'master' || preview;
  const { locale, asPath } = useRouter();

  const { t } = useTranslation('common');
  const { isLoggedIn } = useProfile();

  return (
    <>
      {isDev && (
        <Portal>
          <RefreshBanner environment={environment} preview={preview} />
        </Portal>
      )}
      <div className={twMerge(isDev && 'dev-wrapper', 'min-h-[100vh]', WrapperStyles({ variant }), className)}>
        {navigation && <Header localizedSlugs={localizedSlugs} nav={navigation} type={type} />}

        {!!crumbles?.length && (
          <Container className={twMerge('pt-7', breadcrumbWrapperClassName)}>
            <BreadCrumbs
              className={breadcrumbClassName}
              crumbles={crumbles}
              homeLabel={t('home')}
              icon={isLoggedIn && isB2b ? faUser : faHouse}
              locale={locale}
              path={asPath}
            />
          </Container>
        )}

        <Container className={twMerge('flex flex-grow flex-col', containerWrapperClassName)}>
          <div className={twMerge(childrenClassName)}>{children}</div>
          {navigation && (
            <Footer
              className="self-end"
              form={<SubscribeForm />}
              legalLinks={navigation.legalLinks}
              locale={locale}
              logo={navigation.footerLogo}
              navigationItems={navigation.footerNavigationItems}
              paymentMethods={navigation.paymentMethods}
              socialLinks={navigation.socialLinks}
              type={type}
            />
          )}
        </Container>

        <ScrollToTop />
        {displayPWAPopup && <PWAWrapper />}
      </div>
    </>
  );
};

export default Layout;
